import React, { useState, useEffect } from "react";
import { TypedTerminal, TerminalLine } from "react-component-typed-terminal";
import styled from "styled-components";

import "./app.css";

const typedJsProps = {
    loop: false,
    typeSpeed: 40,
    showCursor: false,
}

const terminalData = [
    {
      command: "ls -a ./folder-1",
      results: ["file 1", "file 2", "file 3", "file 4"],
    },
    {
      command: "ls -a ./folder-2",
      results: ["file 1", "file 2", "file 3", "file 4"],
    },
]

function PromptLine({prompt, string, speed}) {
  return (
    <TerminalLine
      promptText={prompt || "visitor@trentlsme:"}
      typedJsProps={{
        loop: false,
        showCursor: false,
        strings: [string],
        typeSpeed:speed || 80,
      }}
    />
  ) 
}

function TypedLine({delay, string, speed}) {

  const [show, setShow] = useState(false);

  useEffect(() => {
    const interval = setInterval(function(){
      setShow(true);
    },delay)
    return () => clearInterval(interval);
  },[])

  if(!show){
    return <></>
  }

  return (
    <PromptLine prompt=" " string={string} speed={speed || 1}/>
  )
}

function About() {
  return (
    <>
      <PromptLine string={"cat about.txt"} speed={50}/>
      <br/>
      <TypedLine 
        string={`
          󰅂 Hello! My name is Trent Slutzky. 
          I'm currently headquartered in NYC, and work as a 
          software engineer for 
          <a target="_blank" href="https://www.narmi.com">Narmi</a>.
        `}
        delay={1500}
        speed={1}
      />
      <br/>
      <TypedLine
        string={`󰅂 write more about myself`}
        delay={4000}
      />
      <br/>
      <DelayedLine delay={6000}><span className="divider">----</span></DelayedLine>
      <br/>
      <TypedLine
        string="Here are some links to find me elsewhere on the internet:"
        speed={1}
        delay={6000}
      />
      <br/>
      <DelayedLine select={true} delay={7000}> linkedin.com/in/tlslutzky/</DelayedLine>
      <DelayedLine select={true} delay={7200}> github.com/trentslutzky</DelayedLine>
      <br/>
      <DelayedLine delay={10000}><span className="divider">----</span></DelayedLine>
    </>
  )
}

function Projects() {
  return (
    <>
      <PromptLine string={"ls ./projects/"} speed={50}/>
      <br/>
      <TypedLine
        delay={1400}
        string={`Below are links to some of my projects`}
      />
      <br/>
      <DelayedLine delay={2000}># Software</DelayedLine>
      <DelayedLine delay={2200} select={true}> trentls.me (this site)</DelayedLine>
      <DelayedLine delay={2800} select={true}> <span style={{color: "#"}}>h</span><span style={{color: "#ff7575"}}>u</span><span style={{color: "#6eb0ff"}}>e</span></DelayedLine>
      <DelayedLine delay={2400} select={true}> esper-reader</DelayedLine>
      <DelayedLine delay={3000} select={true}> flag.quest</DelayedLine>
      <br/>
      <DelayedLine delay={2000}># Robotics</DelayedLine>
      <DelayedLine delay={2200} select={true}> NASA Robotic Mining Competition</DelayedLine>
      <DelayedLine delay={2400} select={true}> Origami Robotic Endoscope</DelayedLine>
      <br/>
      <DelayedLine delay={2000}># Keyboards</DelayedLine>
      <DelayedLine delay={2200} select={true}> Esper ErgoX v1</DelayedLine>
      <DelayedLine delay={2400} select={true}> Esper v2</DelayedLine>
      <DelayedLine delay={2200} select={true}> Esper Keeb V3</DelayedLine>
    </>
  )
}

export const App = () => {

  const [page_state, setPageState] = useState(-1); 

  const showAbout = () => {
    setPageState(0);
  }

  const showProjects = () => {
    setPageState(1);
  }

  return (
    <div id="terminal-container">
      <PromptLine speed={60} string={`echo <span class="green">"$(</span>cat <span class="yellow">welcome.txt</span><span class="green">)"</span>`}/>
      <DelayedLine delay={2100}><br/>Welcome to trentls.me! click on some files below:<br/><br/></DelayedLine>
      <DelayedLine delay={2550} select={true} onClick={showAbout}>- about.txt {page_state == 0 && "<---"}</DelayedLine>
      <DelayedLine delay={2600} select={true} onClick={showProjects}>- projects.txt {page_state == 1 && "<---"}</DelayedLine>
      <DelayedLine delay={2650} select={true}>- photography.txt</DelayedLine>
      {page_state == 0 && <><br/><About/></>}
      {page_state == 1 && <><br/><Projects/></>}
    </div>
  )
}

const DelayedDiv = styled.div`
  visibility: hidden;
  animation: makeVisible 0s ${props => props.delay}ms forwards;
  @keyframes makeVisible {
    to   { visibility: visible; }
  }
`;

const DelayedLine = styled.p`
  user-select: none;
  color: ${props => props.color || "#ccc"};
  visibility: hidden;
  animation: makeVisible 0s ${props => props.delay}ms forwards;
  @keyframes makeVisible {
    to   { visibility: visible; }
  }
  ${props => props.select == true ?`
    padding-left: 1ch;
    &:hover { 
      opacity: 1;
      background: #ffffff05;
    }
    &:active {
     opacity: 1;
     background: #ffffff10;
    }
    cursor: pointer;
    color: #98c379;
    opacity: 0.7;
    `
    :``
  }
`;
